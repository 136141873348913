import {
  get_api_faqs_details as getApiFaqsDetails,
  get_api_teamblog as getApiTeamblog,
  get_api_team_new as getApiTeamNew,
  get_api_profile as getApiProfile,
  get_api_posts as getApiPosts,
} from 'components/api';
import { isWeChat } from 'components/env';
import { getContentId } from 'components/util';
import { getPageName, PAGE_NAME } from 'components/util/get-mobile-path-name';
import { wxShare } from 'components/util/wx-share';

interface productInfo {
  /** 产品名称 */
  name: string;
  /** 产品logo地址 */
  logo: string;
  id: number;
}

/**
 * 通用设置微信分享页面标题和logo等相关信息
 * 除开特殊处理外均用通用配置
 * 特殊处理页面：帖子详情、常见问题详情、博客详情、个人主页、我们的故事
 */
const setWxShare = (productInfo:productInfo) => {
  if (process.env.TARO_ENV === 'weapp' || !isWeChat()) return;


  const contentId = getContentId() || 0;
  const pathName = getPageName();
  const shareConfig = { title: productInfo.name, imgUrl: productInfo.logo, desc: '' };

  // 常见问题详情
  if (pathName === PAGE_NAME.FAQ_DETAIL) {
    getApiFaqsDetails({ productId: productInfo.id, postId: contentId })
      .then((res) => {
        shareConfig.title = `${res.data.title} - ${productInfo.name}`;
        wxShare(shareConfig);
      });
    return;
  }

  // 博客详情
  if (pathName === PAGE_NAME.BLOG_DETAIL) {
    getApiTeamblog({ productId: productInfo.id, blogId: contentId })
      .then((res) => {
        shareConfig.title = `${res.data.title} - ${productInfo.name}`;
        wxShare(shareConfig);
      });
    return;
  }
  // 帖子详情
  if (pathName === PAGE_NAME.POST_DETAIL) {
    getApiPosts({ productId: productInfo.id, postId: contentId })
      .then((res) => {
        shareConfig.title = `${res.data.content} - ${productInfo.name}`;
        wxShare(shareConfig);
      });
    return;
  }

  // 我们的故事
  if (pathName === PAGE_NAME.BLOG_TEAM) {
    getApiTeamNew({ productId: productInfo.id })
      .then((res) => {
        shareConfig.title = `${res.data.team_title} - ${res.data.team_name}`;
        wxShare(shareConfig);
      });
    return;
  }

  // 用户主页
  if (pathName === PAGE_NAME.PROFILE) {
    getApiProfile({ productId: productInfo.id, userId: contentId })
      .then((res) => {
        shareConfig.title = `${res.data.nickname} - ${productInfo.name}`;
        wxShare(shareConfig);
      });
    return;
  }

  wxShare(shareConfig);
};

export default setWxShare;
