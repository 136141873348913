import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash-es';
import { IconEmtydata } from 'components/IconSystem';
import { setRootFontSize } from 'components/util';
import ariaAutoFocus from 'components/util/aria/auto-focus';
import { FooterBar } from 'components/footer-bar';
import {
  get_api_products_info as getApiProductsInfo,
  initUserInfo,
} from 'components/api';
import { Loading } from 'components/loading';
import getProductId from 'components/util/get-product-id';
import { EmbedLayout } from 'components/embed-layout';
import { PvCollector } from 'components/report';
import { redirectToBlacklistPage } from 'components/util/redirect';
import setWxShare from './common/set-wx-share';
import Tag from 'components/tag';
import 'components/skeleton/style.less';
import 'components/css/mobile/reset.less';
import 'components/dark-mode/style.less';
import './style.less';
import { pageInfo } from 'components/util/page-info';
import { initDarkMode } from 'components/dark-mode';
import { initCoralMobile } from '@tencent/coral-mobile-comment-list';


const STATUS = {
  /** 默认 */
  DEFAULT: 'DEFAULT',
  /** 加载态 */
  LOADING: 'LOADING',
  /** 空状态 */
  EMPTY: 'EMPTY',
  /** 错误页面 */
  ERROR: 'ERROR',
};
const cls = 'base-page-mobile';

// 是否需要更新session;
const hasOtherSession = pageInfo.params.get('nickname') || pageInfo.params.get('openid') || pageInfo.params.get('avatar');
class PageBase extends Component {
  constructor(props) {
    super(props);

    this.productId = getProductId();
    this.initUserInfo();
    this.getProductInfo();
    // 初始化暗黑模式
    initDarkMode();
    this.initCoralMobile();
    this.state = {
      // 如果有要更新session 阻塞子页面渲染
      loaded: !hasOtherSession,
      loginProvider: {
        allow: [],
        deny: [],
      },
    };
  }

  componentDidMount() {
    /** pv upload */
    new PvCollector({ productId: this.productId }).report();
    /** resize the font size */
    setRootFontSize();
    // ariaAutoFocus();
  }

  /** 初始化search注入的一些用户信息 */
  async initUserInfo() {
    if (process.env.TARO_ENV !== 'weapp') {
      const initInfo = await initUserInfo(this.productId, location.search).catch(() => { });
      if (initInfo?.data?.reload) {
        location.reload();
      }
    }
    this.setState({ loaded: true });
  }

  initCoralMobile = () => {
    if (process.env.TARO_ENV !== 'h5') return;
    // 只有不使用taro的编译需要注册移动端组件
    initCoralMobile({});
  }

  /** 获取产品信息 */
  getProductInfo() {
    const { setProductInfo } = this.props;
    return getApiProductsInfo(this.productId)
      .then((res) => {
        this.highRisk(res.data);
        this.setPageTitle(res.data);
        this.setLoginProvider(res.data);

        if (setProductInfo) {
          setProductInfo(res.data);
        }
        setWxShare({
          // eslint-disable-next-line camelcase
          name: res.data?.product_name,
          logo: res.data?.logo,
          id: res.data?.id,
        });
        return res.data;
      })
      .catch((err) => {
        if (setProductInfo) {
          setProductInfo({});
        }
        return err;
      });
  }


  // 设置产品权限，是否允许非游客态登录
  setLoginProvider = (productInfo) => {
    this.setState({
      loginProvider: productInfo.login_provider,
    });
  }

  /** 设置页面标题 */
  setPageTitle = (productInfo) => {
    document.title = productInfo.product_name;
  }

  // 对于高风险项目跳转到提示页
  highRisk = (productInfo) => {
    if (productInfo.credit_level === 'high_risk') {
      redirectToBlacklistPage();
    }
  }

  renderFooterBar = () => {
    const { loginProvider } = this.state;
    const { showFooterBar } = this.props;
    const { allow, deny } = loginProvider;
    // TODO 移植的是否允许游客逻辑，感觉可以优化。先标记
    const allowTourist = (isEmpty(allow) && isEmpty(deny))
      || (isEmpty(allow) && deny.indexOf('system') === -1)
      || allow.indexOf('system') >= 0;
    return showFooterBar && (
      <FooterBar
        allowTourist={allowTourist}
      />
    );
  }

  renderChild = () => {
    const { status, children } = this.props;
    const { loaded } = this.state;
    if (status === STATUS.LOADING || !loaded) {
      return (
        <Tag.div className={`${cls}__main`}>
          <Loading />
        </Tag.div>
      );
    }
    if (status === STATUS.EMPTY) {
      return (
        <Tag.div className={`${cls}__main`}>
          <Tag.div className={`${cls}__empty`} >
            <IconEmtydata className={`${cls}__empty-icon`} />
            <Tag.div>该分类下没有内容</Tag.div>
          </Tag.div>
        </Tag.div>
      );
    }
    return children;
  }

  render() {
    const {
      className,
      setProductInfo,
      showFooterBar,
      checkHighRisk,
      ...restProps
    } = this.props;
    return (
      <EmbedLayout
        className={classnames(cls, className)}
        footer={this.renderFooterBar()}
        {...restProps}
      >
        {this.renderChild()}
      </EmbedLayout>
    );
  }
}

PageBase.defaultProps = {
  status: STATUS.DEFAULT,
  showFooterBar: true,
  checkHighRisk: true,
  ariaHidden: false,
};
PageBase.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
  showFooterBar: PropTypes.bool,
  checkHighRisk: PropTypes.bool,
  setProductInfo: PropTypes.func,
};
PageBase.STATUS = STATUS;

export default PageBase;
