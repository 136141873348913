import { getProductId, localStorage } from 'components/util';
import { getApiV1ProductsAttribute } from 'components/api';
import { ATTRIBUTE_TYPE, ATTRIBUTE_STATUS } from 'components/constants/app';

/**
 * 由于是异步加载暗黑模式配置，存在加载时间，就会有一个从白到黑的过程，为了减少影响做2个优化
 * 1. 取大多数产品的配置作为默认设置，也就是默认打开暗黑模式
 * 2. 客户端本地缓存上一次配置加载结果，避免闪白
 */


// 读取产品配置 判断是否需要适配暗黑模式
const initDarkMode = () => {
  const productId = getProductId();
  const DARK_MODE_STATE = `DARK_MODE_STATE_${productId}`;
  // 小程序暗黑模式不在这里控制
  if (process.env.TARO_ENV === 'weapp') return;
  const darkModeState = localStorage.getItem(DARK_MODE_STATE) !== 'false';
  setDarkMode(darkModeState);

  getApiV1ProductsAttribute({ productId, type: ATTRIBUTE_TYPE.DARK_MODE })
    .then((res) => {
      const state = res.data === ATTRIBUTE_STATUS.ON;
      localStorage.setItem(DARK_MODE_STATE, state);
      // 产品配置更新再重新设置
      if (state !== darkModeState) {
        setDarkMode(state);
      }
    });
};

const setDarkMode = (state:boolean) => {
  if (state) {
    document.getElementsByTagName('html')[0]?.classList.add('dark-mode');
  } else {
    document.getElementsByTagName('html')[0]?.classList.remove('dark-mode');
  }
};

export { initDarkMode };
