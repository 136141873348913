import { isWeChat } from 'components/env';
import WXJSSDK from 'components/wxjssdk';

// 单例 减少重新加载
const wxjssdk = new WXJSSDK();

export const wxShare = ({ title, desc, imgUrl }) => {
  if (!isWeChat) return;

  wxjssdk.setShare({ title, desc, link: location.href, imgUrl });
};

