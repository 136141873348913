/**
 * 为了帮助匹配当前页面路径做的页面名称匹配
 */

/** 页面名称枚举值 */
const PAGE_NAME =  {
  /** 常见问题详情页 */
  FAQ_DETAIL: 'faqs',
  /** 帖子详情页 */
  POST_DETAIL: 'post',
  /** 话题详情页 */
  TOPIC_DETAIL: 'topic-detail',
  /** 博客详情页 */
  BLOG_DETAIL: 'blog',
  /** 常见问题列表页 */
  FAQ_LIST: 'faqs-more',
  /** 博客列表页 */
  BLOG_LIST: 'blog-archive',
  /** 我们的故事 */
  BLOG_TEAM: 'team',
  /** 个人主页 */
  PROFILE: 'profile',
  /** 发帖页 */
  NEW_POST: 'new-post',
  /** 选择分类 */
  PRO_SEC: 'pro-sec',
  /** 常见问题分类 */
  FAQ_CATEGORY: 'faqs-category',
  /** 常见问题二级分类 */
  FAQ_SINGLE: 'faqs-more',
  /** 未知页面 */
  UNKNOWN: 'UNKNOWN',
};


const getPageName = () => {
  const path = location.pathname.match(/\/\d+\/([\w_-]+)/i);
  if (path && path[1] && Object.values(PAGE_NAME).includes(path[1])) {
    return path[1];
  }

  return PAGE_NAME.UNKNOWN;
};

export { PAGE_NAME, getPageName };
